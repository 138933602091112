import { useState } from 'react';

import { default as MuiChip } from '@mui/material/Chip';
import { ChipProps } from './types';
import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';
import React from 'react';
import { StyledChipButton, StyledClose } from './styles';
export * from '@mui/material/Chip';

const Chip: React.FC<ChipProps> = ({
  avatar,
  classes,
  clickable,
  color = 'primary',
  'data-testid': testid,
  disabled,
  icon,
  label,
  onClick,
  onDelete,
  size,
  skipFocusWhenDisabled,
  style,
  sx,
  variant = 'outlined',
  'aria-label': ariaLabel,
}) => {
  const [clicked, setClicked] = useState<boolean>(false);

  const getVariant = (clicked: boolean) => {
    if (variant === 'filled') {
      return clicked ? 'outlined' : 'filled';
    } else return clicked ? 'filled' : 'outlined';
  };

  const getClickable = () => {
    if (clickable !== undefined) {
      return clickable;
    }
    if (onClick) {
      return true;
    } else {
      return false;
    }
  };

  const createDataTestId = (id?: string) => {
    if (id !== undefined) {
      return `chip:${id}`;
    }
    return 'chip';
  };

  const variantColorMap = {
    outlined: {
      default: tannerGray['200'],
      primary: tannerGray['200'],
      secondary: tannerGray['200'],
      error: tannerSemantic.error['100'],
      info: tannerSemantic.info['100'],
      success: tannerSemantic.success['100'],
    },
    filled: {
      default: tannerSemantic.info['300'],
      primary: tannerSemantic.info['300'],
      secondary: tannerSemantic.info['300'],
      error: tannerSemantic.error['300'],
      info: tannerSemantic.info['300'],
      success: tannerSemantic.success['300'],
    },
  };
  const getCloseColor = () => {
    return getVariant(clicked)
      ? variantColorMap.outlined[color] || tannerGray['200']
      : variantColorMap.filled[color] || tannerSemantic.info['300'];
  };

  return (
    <MuiChip
      avatar={avatar}
      classes={classes}
      clickable={getClickable()}
      color={color}
      data-testid={createDataTestId(testid)}
      deleteIcon={
        <StyledChipButton tabIndex={-1} closeColor={getCloseColor()}>
          <StyledClose
            titleAccess="Remove"
            data-testid={`${createDataTestId(testid)}:deleteicon`}
          />
        </StyledChipButton>
      }
      disabled={disabled}
      icon={icon}
      label={label}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          setClicked(!clicked);
          onClick(e);
        }
      }}
      onDelete={onDelete}
      size={size}
      skipFocusWhenDisabled={skipFocusWhenDisabled}
      style={style}
      sx={sx}
      variant={getVariant(clicked)}
      aria-label={ariaLabel}
    />
  );
};

export default Chip;
