import React from 'react';
import { ColorLabel, Color, BoldText, NormalText } from './styles';
import { ColorBoxProps } from './types';
import Box from '../Box';

const ColorBox: React.FC<ColorBoxProps> = ({
  color,
  label,
  isSemantic,
  bold,
}) => {
  const [mainLabel, subLabel] = label.split(' (');
  return (
    <Box>
      <Color color={color} label={label} isSemantic={isSemantic} />
      <ColorLabel
        variant={label?.includes('Primary Brand Color') ? 'h5' : 'body2'}
        label={label}
        gutterBottom
      >
        {bold ? (
          <>
            {mainLabel.split('/').map((part, index) => (
              <BoldText key={index}>
                {part}
                {index < mainLabel.split('/').length - 1 && '/'}
              </BoldText>
            ))}
            {subLabel && <NormalText>({subLabel})</NormalText>}
          </>
        ) : (
          <span>{label}</span>
        )}
      </ColorLabel>
    </Box>
  );
};
export default ColorBox;
