import { styled } from '@mui/system';
import { tannerBlack, tannerGray, tannerPurple, tannerWhite } from './colors';
import TextField from '../TextField';
import Typography from '../Typography';
import Box from '../Box';
import { ColorBoxLabelProps } from './types';

export const RootBox = styled(Box)({
  marginBottom: '24px',
});

export const TitleTypography = styled(Typography)({
  marginBottom: '24px',
});

export const ClientColorTitleTypography = styled(Typography)({
  display: 'inline',
  color: tannerPurple['500'],
});

export const StyledTextField = styled(TextField)({
  marginTop: '24px',
  width: '281px',
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
});

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: 12,
  paddingTop: '24px',
});

export const labels = [
  'Client Brand/25',
  'Client Brand/50',
  'Client Brand/100',
  'Client Brand/200',
  'Client Brand/300',
  'Client Brand/400',
  'Primary Brand Color Client Brand/500',
  'Client Brand/600',
  'Client Brand/700',
  'Client Brand/800',
  'Client Brand/900',
];

export const baseColors = [
  tannerWhite,
  tannerWhite,
  tannerWhite,
  tannerWhite,
  tannerWhite,
  tannerWhite,
  tannerBlack,
  tannerBlack,
  tannerBlack,
  tannerBlack,
  tannerBlack,
];

export const Color = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<ColorBoxLabelProps & { color?: string; isSemantic?: boolean }>`
  width: 110px;
  height: ${(props) =>
    props.isSemantic && !props.label?.includes('500') ? '32px' : '68px'};
  background-color: ${(props) => props.color};
  border-radius: 8px;
  border: ${(props) =>
    props.color === tannerWhite ? `1px solid ${tannerGray['200']}` : 'none'};
`;

export const ColorLabel = styled(Typography)<{
  bold?: boolean;
  label?: string;
}>(({ bold, label }) => ({
  marginTop: '8px',
  fontSize: label?.includes('Primary Brand Color') ? '10px' : '12px',
  fontWeight: bold ? 'bold' : 'normal',
}));

export const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

export const NormalText = styled(Typography)({
  fontWeight: 'normal',
});
