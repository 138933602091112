import { tannerSemantic } from './colors';
import { ColorBoxProps } from './types';

const INFORMATION_COLORS_GRID: ColorBoxProps[] = [
  { label: 'Semantic/info 25', color: tannerSemantic['info']['25'] },
  { label: 'Semantic/error 25', color: tannerSemantic['error']['25'] },
  { label: 'Semantic/warning 25', color: tannerSemantic['warning']['25'] },
  { label: 'Semantic/success 25', color: tannerSemantic['success']['25'] },

  { label: 'Semantic/info 50', color: tannerSemantic['info']['50'] },
  { label: 'Semantic/error 50', color: tannerSemantic['error']['50'] },
  { label: 'Semantic/warning 50', color: tannerSemantic['warning']['50'] },
  { label: 'Semantic/success 50', color: tannerSemantic['success']['50'] },

  { label: 'Semantic/info 100', color: tannerSemantic['info']['100'] },
  { label: 'Semantic/error 100', color: tannerSemantic['error']['100'] },
  { label: 'Semantic/warning 100', color: tannerSemantic['warning']['100'] },
  { label: 'Semantic/success 100', color: tannerSemantic['success']['100'] },

  { label: 'Semantic/info 200', color: tannerSemantic['info']['200'] },
  { label: 'Semantic/error 200', color: tannerSemantic['error']['200'] },
  { label: 'Semantic/warning 200', color: tannerSemantic['warning']['200'] },
  { label: 'Semantic/success 200', color: tannerSemantic['success']['200'] },

  { label: 'Semantic/info 300', color: tannerSemantic['info']['300'] },
  { label: 'Semantic/error 300', color: tannerSemantic['error']['300'] },
  { label: 'Semantic/warning 300', color: tannerSemantic['warning']['300'] },
  { label: 'Semantic/success 300', color: tannerSemantic['success']['300'] },

  { label: 'Semantic/info 400', color: tannerSemantic['info']['400'] },
  { label: 'Semantic/error 400', color: tannerSemantic['error']['400'] },
  { label: 'Semantic/warning 400', color: tannerSemantic['warning']['400'] },
  { label: 'Semantic/success 400', color: tannerSemantic['success']['400'] },

  { label: 'Semantic/info 500', color: tannerSemantic['info'].color },
  { label: 'Semantic/error 500', color: tannerSemantic['error'].color },
  { label: 'Semantic/warning 500', color: tannerSemantic['warning'].color },
  { label: 'Semantic/success 500', color: tannerSemantic['success'].color },

  { label: 'Semantic/info 600', color: tannerSemantic['info']['600'] },
  { label: 'Semantic/error 600', color: tannerSemantic['error']['600'] },
  { label: 'Semantic/warning 600', color: tannerSemantic['warning']['600'] },
  { label: 'Semantic/success 600', color: tannerSemantic['success']['600'] },

  { label: 'Semantic/info 700', color: tannerSemantic['info']['700'] },
  { label: 'Semantic/error 700', color: tannerSemantic['error']['700'] },
  { label: 'Semantic/warning 700', color: tannerSemantic['warning']['700'] },
  { label: 'Semantic/success 700', color: tannerSemantic['success']['700'] },

  { label: 'Semantic/info 800', color: tannerSemantic['info']['800'] },
  { label: 'Semantic/error 800', color: tannerSemantic['error']['800'] },
  { label: 'Semantic/warning 800', color: tannerSemantic['warning']['800'] },
  { label: 'Semantic/success 800', color: tannerSemantic['success']['800'] },

  { label: 'Semantic/info 900', color: tannerSemantic['info']['900'] },
  { label: 'Semantic/error 900', color: tannerSemantic['error']['900'] },
  { label: 'Semantic/warning 900', color: tannerSemantic['warning']['900'] },
  { label: 'Semantic/success 900', color: tannerSemantic['success']['900'] },

  { label: 'Semantic/info 1000', color: tannerSemantic['info']['1000'] },
  { label: 'Semantic/error 1000', color: tannerSemantic['error']['1000'] },
  { label: 'Semantic/warning 1000', color: tannerSemantic['warning']['1000'] },
  { label: 'Semantic/success 1000', color: tannerSemantic['success']['1000'] },
];

const BACKGROUND_COLORS = [
  {
    label: 'Semantic/info/background (info/50)',
    color: tannerSemantic['info']['50'],
  },
  {
    label: 'Semantic/error/background (error/50)',
    color: tannerSemantic['error']['50'],
  },
  {
    label: 'Semantic/warning/background (warning/50)',
    color: tannerSemantic['warning']['50'],
  },
  {
    label: 'Semantic/success/background (success/50)',
    color: tannerSemantic['success']['50'],
  },

  {
    label: 'Semantic/info/color (info/500)',
    color: tannerSemantic['info']['500'],
  },
  {
    label: 'Semantic/error/color (error/500)',
    color: tannerSemantic['error']['500'],
  },
  {
    label: 'Semantic/warning/color (warning/500)',
    color: tannerSemantic['warning']['500'],
  },
  {
    label: 'Semantic/success/color (success/500)',
    color: tannerSemantic['success']['500'],
  },
];

export { BACKGROUND_COLORS, INFORMATION_COLORS_GRID };
