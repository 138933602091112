import { useContext, useMemo } from 'react';
import LayoutContext from '../../context/Layout';

const useCatalogLink = ({
  referrerUrl = '',
  lang,
}: {
  referrerUrl?: string;
  lang?: string | null;
}) => {
  const [currentIdentityQuery, layoutQuery] = useContext(LayoutContext);

  const catalogLink = useMemo(() => {
    const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
    const defaultBank =
      layoutQuery?.data?.bankBalance?.find((bank) => bank.defaultBank) ||
      layoutQuery?.data?.bankBalance?.[0];

    if (!currentIdentity || !defaultBank) return '';

    const queryParams = new URLSearchParams({
      countryCode:
        currentIdentity.profile?.catalogCoreCountry?.iso2Code ||
        currentIdentity.profile?.homeCoreLocation?.country ||
        '',
      programId: defaultBank?.programId?.toString() || '',
      refererUrl: referrerUrl,
      locale: (lang || navigator.languages?.[0] || navigator.language).replace(
        '-',
        '_',
      ),
    });

    return `/catalog?${queryParams}`;
  }, [referrerUrl, lang, currentIdentityQuery?.data, layoutQuery?.data]);

  return catalogLink;
};

export default useCatalogLink;
