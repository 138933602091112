import React, { FormEvent } from 'react';
import TextField from '../TextField';
import InputAdornment from '../InputAdornment';
import IconButton from '../IconButton';
import LinearProgress from '../LinearProgress';
import { Eye, EyeSlash } from '@octanner/prism-icons';
import { useTranslation } from 'react-i18next';
import usePasswordValidator from './usePasswordValidator';
import { Alert, Grid } from '..';
import { PasswordFormProps } from './types';
import {
  CancelButton,
  StyledGrid,
  StyledUL,
  classes,
  SaveButton,
  StyledTypography,
  Icon,
} from './styles';

const PasswordForm: React.FC<PasswordFormProps> = ({
  currentPasswordErrorText,
  newPasswordErrorText,
  errorText: customErrorText,
  loading = false,
  onSubmit,
  onCancel,
  showCurrentPasswordField = false,
  showHeader = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const passwordState = usePasswordValidator(
    password,
    newPassword,
    confirmPassword,
  );
  const isValidated = newPassword.length > 0;

  const handleSubmit = (
    // ? Div or Button element to maintain compatibility, even though the grid one does not work!
    event: FormEvent<HTMLDivElement | HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (loading) return;
    if (passwordState.isValid) {
      onSubmit({ password, newPassword });
    } else {
      passwordState.actions.validate();
    }
  };

  const findPasswordStrength = () =>
    passwordState.strength === 0
      ? ' '
      : passwordState.strength > 0 && passwordState.strength < 50
      ? t('prism-header:password-weak', ' Weak')
      : passwordState.strength >= 50 && passwordState.strength < 100
      ? t('prism-header:password-medium', ' Medium')
      : t('prism-header:password-strong', ' Strong');

  const generateAriaLabel = (
    isValidated: boolean,
    isPassed: boolean,
    requirementDescription: string,
  ): string =>
    isValidated
      ? isPassed
        ? `passed ${requirementDescription}`
        : `failed ${requirementDescription}`
      : `bullet ${requirementDescription}`;

  return (
    // TODO: Remove the onSubmit for the grid for Grid2, I do not believe this is a proper method to handle submission especially when this isnt a form!
    <StyledGrid container spacing={6} onSubmit={handleSubmit} {...props}>
      {showHeader && (
        <Grid item xs={12}>
          <StyledTypography variant="h5" component="h3" isHeader={true}>
            {t('prism-header:create-new-password', 'Set new password')}
          </StyledTypography>
        </Grid>
      )}
      {showCurrentPasswordField && (
        <Grid item xs={12}>
          <TextField
            label={t('prism-header:current-password', 'Current password')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={Boolean(currentPasswordErrorText)}
            helperText={currentPasswordErrorText}
            required
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            data-testid="current-password-field"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((v) => !v)}
                    size="large"
                  >
                    {showPassword ? <Eye /> : <EyeSlash />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label={t('prism-header:new-password', 'New password')}
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={
            Boolean(newPasswordErrorText) ||
            Boolean(passwordState.passwordError)
          }
          helperText={passwordState.passwordError || newPasswordErrorText}
          required
          fullWidth
          data-testid="new-password-field"
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((show) => !show)}
                  size="large"
                >
                  {showNewPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t('prism-header:confirm-password', 'Confirm new password')}
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          error={Boolean(passwordState.confirmPasswordError)}
          helperText={passwordState.confirmPasswordError}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={passwordState.actions.validate}
          required
          fullWidth
          data-testid="confirm-password-field"
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="inherit"
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword((show) => !show)}
                  size="large"
                >
                  {showConfirmPassword ? <Eye /> : <EyeSlash />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTypography isHeader={true}>
          {t('prism-header:must-contain', 'Must contain:')}
        </StyledTypography>
        <StyledUL>
          <li>
            <StyledTypography
              role="status"
              isValidated={isValidated}
              isPassed={passwordState.isMinLength}
              data-testid="password-length-requirements"
              aria-live="polite"
              aria-label={generateAriaLabel(
                isValidated,
                passwordState.isMinLength,
                'password length requirements: Must have 8 or more characters',
              )}
            >
              <Icon
                isValidated={isValidated}
                isPassed={passwordState.isMinLength}
                aria-hidden="true"
              />{' '}
              <div aria-hidden="true">
                {t('prism-header:eight-or-more-chars', '8 or more characters')}
              </div>
            </StyledTypography>
          </li>
          <li>
            <StyledTypography
              role="status"
              isValidated={isValidated}
              isPassed={passwordState.hasUpperAndLower}
              data-testid="uppercase-and-lowercase-requirements"
              aria-label={generateAriaLabel(
                isValidated,
                passwordState.hasUpperAndLower,
                'uppercase and lowercase letters requirements: Must have at least one uppercase and one lowercase letter',
              )}
              aria-live="polite"
            >
              <Icon
                isValidated={isValidated}
                isPassed={passwordState.hasUpperAndLower}
                aria-hidden="true"
              />{' '}
              <div aria-hidden="true">
                {t(
                  'prism-header:uppercase-and-lowercase',
                  'Uppercase & lowercase letters',
                )}
              </div>
            </StyledTypography>
          </li>
          <li>
            <StyledTypography
              role="status"
              isValidated={isValidated}
              isPassed={passwordState.hasNumber}
              data-testid="numerical-requirements"
              aria-label={generateAriaLabel(
                isValidated,
                passwordState.hasNumber,
                'numerical requirements: Must have at least one number',
              )}
              aria-live="polite"
            >
              <Icon
                isValidated={isValidated}
                isPassed={passwordState.hasNumber}
                aria-hidden="true"
              />{' '}
              <div aria-hidden="true">
                {t('prism-header:at-least-one-number', 'At least one number')}
              </div>
            </StyledTypography>
          </li>
          <li>
            <StyledTypography
              role="status"
              isValidated={isValidated}
              isPassed={passwordState.hasSpecialChar}
              data-testid="special-character-requirements"
              aria-label={`${generateAriaLabel(
                isValidated,
                passwordState.hasSpecialChar,
                'special character requirements: Must have at least one special character',
              )}.`}
              aria-live="polite"
            >
              <Icon
                isValidated={isValidated}
                isPassed={passwordState.hasSpecialChar}
                aria-hidden="true"
              />{' '}
              <div aria-hidden="true">
                {t(
                  'prism-header:at-least-one-special-char',
                  'At least one special character',
                )}
              </div>
            </StyledTypography>
          </li>
        </StyledUL>
      </Grid>

      <Grid item xs={12}>
        <div>
          <StyledTypography
            role="status"
            isHeader={true}
            data-testid="password-strength-title"
            id="passwordStrength"
            aria-live="assertive"
            aria-label={
              passwordState.strength > 0
                ? `Password strength: ${
                    passwordState.strength
                  }% which is ${findPasswordStrength()}.`
                : 'Password strength: 0%'
            }
          >
            <div>
              {t('prism-header:password-strength', 'Password strength:')}
              {findPasswordStrength()}
            </div>
          </StyledTypography>
          <LinearProgress
            aria-hidden="true"
            aria-labelledby="passwordStrength"
            aria-valuenow={passwordState.strength}
            aria-valuemin={0}
            aria-valuemax={100}
            variant="determinate"
            value={passwordState.strength}
            color={
              passwordState.strength < 50
                ? 'secondary'
                : passwordState.strength >= 50 && passwordState.strength < 100
                ? 'warning'
                : 'primary'
            }
            data-testid="password-strength-progress-bar"
            classes={{
              root: classes.root,
              colorPrimary: classes.colorPrimary,
              colorSecondary: classes.colorSecondary,
              bar: classes.bar,
              barColorPrimary: classes.barColorPrimary,
              barColorSecondary: classes.barColorSecondary,
            }}
          />
        </div>
      </Grid>
      {customErrorText && (
        <Grid item xs={12}>
          <Alert severity="error">{customErrorText}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <SaveButton
          type="submit"
          onClick={handleSubmit}
          color="primary"
          loading={loading}
          data-testid="save-button"
        >
          {t('prism-header:save', 'Save')}
        </SaveButton>
      </Grid>
      <Grid container spacing={5} item>
        {onCancel && (
          <CancelButton
            color="secondary"
            onClick={onCancel}
            disabled={loading}
            data-testid="cancel-button"
          >
            {t('prism-header:cancel', 'Cancel')}
          </CancelButton>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default PasswordForm;
