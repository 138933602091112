import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Checkbox } from '../';
import {
  ActionsContainer,
  FormGroupColumns,
  FormControlLabelAligned,
  Container,
} from './styles';
import type { Props, RenderCheckboxFunc } from './types';

const renderCheckboxDefault: RenderCheckboxFunc<any> = (
  { id, name, selected },
  onChange,
  isSelected,
) => {
  return (
    <FormControlLabelAligned
      key={id}
      label={name}
      control={
        <Checkbox
          name={name}
          checked={isSelected || selected || false}
          onChange={onChange}
        />
      }
    />
  );
};

function OptionsList<T = any>({
  onSelectAll,
  onSelectNone,
  onChange,
  isSelected,
  options,
  renderCheckbox = renderCheckboxDefault,
  error,
  onErrorDismiss,
}: Props<T>) {
  const { t } = useTranslation();

  const showSelectAllButtons = !!onSelectAll && !!onSelectNone;

  return (
    <Container>
      {showSelectAllButtons && (
        <ActionsContainer>
          <Button variant="text" onClick={onSelectAll}>
            {t('prism-header:select-all', 'Select all')}
          </Button>
          <Button variant="text" onClick={onSelectNone}>
            {t('prism-header:clear-selections', 'Clear selections')}
          </Button>
        </ActionsContainer>
      )}
      {error && (
        <Alert severity="error" onClose={onErrorDismiss}>
          {error}
        </Alert>
      )}
      <FormGroupColumns options={options}>
        {options?.map((option) =>
          renderCheckbox(option, onChange, isSelected?.(option)),
        )}
      </FormGroupColumns>
    </Container>
  );
}

export default OptionsList;
export { renderCheckboxDefault };
