import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Theme,
  ThemeOptions,
} from '@mui/material/styles';
import { gql, useQuery } from '@apollo/client';
import { deepmerge } from '@mui/utils';
import CssBaseline from '@mui/material/CssBaseline';
import defaultThemeOptions from './defaultTheme';
import {
  Identity,
  Layout,
  CustomerCoreLocaleMapsByCustomerPayLoad,
} from '../Header/interfaces';
import LayoutContext from '../context/Layout';
import { makePalette, colorDisabled } from './utils';

const PRISM_CACHE_KEY = 'prism-ui:theme';

export const CURRENT_IDENTITY_QUERY = gql`
  query getCurrentIdentity {
    currentIdentity {
      dateOfBirth
      nonFedFlag
      nonCredFlag
      profile {
        avatar
        catalogCoreCountry {
          iso2Code
        }
        homeCoreLocation {
          country
        }
        pronoun
      }
      email
      id
      identityUuid
      firstName
      lastName
      preferredName
      language
      jobTitle
      workLocation
      segmentOfficelocation
      businessUnit {
        buName
        parentBusinessUnit {
          buName
        }
      }
      customer {
        id
        customerUuid
        name
        stpNumber
        features {
          effectiveDate
          expirationDate
          featureCode
          featureName
        }
      }
      isManager
      manager {
        id
        identityUuid
        firstName
        lastName
        preferredName
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($id: String!, $language: String!) {
    createUserDefinedFields(input: { id: $id, language: $language }) {
      id
      identityUuid
      language
    }
  }
`;

export const LAYOUT_QUERY = gql`
  query getLayout($locale: String) {
    bankBalance {
      accessCodeDetails {
        accessCode
        expiresAt
        status
      }
      activeAccessCodeCount
      bankName
      defaultBank
      id
      points
      programId
    }
    cultureCloud {
      ccHeader {
        links {
          id
          translationString
          url
        }
      }
    }
    theme(locale: $locale) {
      themeId
      logo {
        value
      }
      canvasImage {
        value
      }
      primaryColor {
        value
      }
    }
    ccIsBellIndicatorActive {
      isBellIndicatorActive
    }
    hasRedeemAwardsPermission {
      hasRedeemAwardsPermission
    }
  }
`;

export const LOCALE_QUERY = gql`
  query getSupportedLocales($input: CustomerCoreLocaleMapsByCustomerInput!) {
    customerCoreLocaleMapsByCustomer(input: $input) {
      nodes {
        locale {
          code
          languageDescription
        }
      }
    }
  }
`;

let themeOptions = {};

try {
  themeOptions = JSON.parse(
    window.localStorage.getItem(PRISM_CACHE_KEY) || '{}',
  );
} catch (err) {
  // TODO: Better logging
}

const baseTheme = createTheme(
  deepmerge(defaultThemeOptions, makePalette(themeOptions)),
);

type Props = PropsWithChildren<{ disableInjection?: boolean }>;
/*
  TODO: Currently we have found no way to test this in a way that provides
  value. If a way to pass material UI JSS down to the DOM becomes possible
  Write a test that validates the mui/prism components wrapped by this
  provider receive the appropriate styles.
*/
const PrismThemeProvider: React.FC<Props> = ({
  children,
  disableInjection = false,
}) => {
  const [theme, setTheme] = useState<Theme>(baseTheme);
  const currentIdentity = useQuery<{ currentIdentity: Identity }>(
    CURRENT_IDENTITY_QUERY,
    {
      errorPolicy: 'all',
    },
  );
  const layout = useQuery<Layout>(LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { locale: currentIdentity?.data?.currentIdentity.language },
    skip: !currentIdentity?.data,
  });

  const localeMap = useQuery<CustomerCoreLocaleMapsByCustomerPayLoad>(
    LOCALE_QUERY,
    {
      errorPolicy: 'all',
      variables: {
        input: {
          customerId:
            currentIdentity?.data?.currentIdentity.customer?.customerUuid,
          isActive: true,
        },
      },
      skip: !currentIdentity?.data?.currentIdentity.customer?.customerUuid,
    },
  );

  useEffect(() => {
    if (!layout.data) return;

    const themeOptions = layout.data.theme;

    try {
      window.localStorage.setItem(
        PRISM_CACHE_KEY,
        JSON.stringify(themeOptions),
      );
    } catch (err) {
      // TODO: Better logging
    }

    const newThemeOptions: ThemeOptions = deepmerge(
      defaultThemeOptions,
      makePalette(themeOptions),
    );

    const newTheme = createTheme(newThemeOptions);

    if (newTheme?.components?.MuiButton?.styleOverrides?.containedPrimary) {
      newTheme.components.MuiButton.styleOverrides.containedPrimary[
        '&.Mui-disabled'
      ] = colorDisabled(newTheme.palette.primary);
      newTheme.components.MuiButton.styleOverrides.containedPrimary[
        '&:focus-visible'
      ] = { backgroundColor: newTheme.palette.primary.dark };
    }

    setTheme(newTheme);
  }, [layout.data]);

  const content = (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutContext.Provider value={[currentIdentity, layout, localeMap]}>
        {children}
      </LayoutContext.Provider>
    </ThemeProvider>
  );

  if (disableInjection) {
    return content;
  }

  return <StyledEngineProvider injectFirst>{content}</StyledEngineProvider>;
};

export default PrismThemeProvider;
