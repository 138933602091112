import styled from '@emotion/styled';
import FormControlLabel from '../FormControlLabel';
import FormGroup from '../FormGroup';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const ActionsContainer = styled.div({
  flex: '1',
  display: 'flex',
  justifyContent: 'space-between',
  pl: '45px',
});

export const FormControlLabelAligned = styled(FormControlLabel)({
  marginLeft: 0, // override the -11px default
});

export const FormGroupColumns = styled(FormGroup)<{ options: any[] }>(
  ({ options }) => ({
    display: 'grid',
    // the 200px is enough to max out at 4 columns for the max page container width
    gridTemplateColumns:
      options.length > 10 ? 'repeat(auto-fill, minmax(200px, 1fr))' : '1fr',
    pl: '45px',
    pr: 3,
  }),
);
