import React from 'react';
import ColorBox from './ColorBox';
import { RootBox, TitleTypography, GridBox } from './styles';
import { tannerGray, tannerBlack, tannerWhite } from './colors';
import { ColorBoxLabelProps } from '@octanner/prism-core/ThemeProvider/types';

const TannerGraysColorsComponent: React.FC<ColorBoxLabelProps> = () => {
  const mapColorEntries = (
    colorName: string,
    colorObject: Record<string, string>,
  ) => {
    return Object?.keys(colorObject)?.map((key) => ({
      label: `${colorName}/${key}`,
      color: colorObject[key],
    }));
  };

  const tannerGrayColors = [...mapColorEntries('TannerGray', tannerGray)];

  tannerGrayColors.splice(12, 2); //removed color and background and added to largeTannerGrayColors for ordering

  const largeTannerGrayColors = [
    { label: 'TannerGray/Black', color: tannerBlack },
    { label: 'TannerGray/White', color: tannerWhite },
    { label: 'TannerGray/background', color: tannerGray.background },
    { label: 'TannerGray/color', color: tannerGray.color },
  ];

  return (
    <RootBox>
      <TitleTypography variant="h3">Grays</TitleTypography>
      <GridBox>
        {tannerGrayColors?.map(({ color, label }) => (
          <ColorBox key={color} color={color} label={label} />
        ))}
        {largeTannerGrayColors?.map(({ color, label }) => (
          <ColorBox key={color} color={color} label={label} />
        ))}
      </GridBox>
    </RootBox>
  );
};

export default TannerGraysColorsComponent;
