import React from 'react';
import MuiIconButton from '@mui/material/IconButton';
import { IconButtonProps } from './types';

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      'aria-label': ariaLabel,
      'aria-pressed': ariaPressed,
      children,
      classes,
      className,
      color = 'default',
      'data-testid': testid,
      disabled,
      disableFocusRipple,
      disableRipple,
      edge = false,
      id,
      onClick,
      size = 'medium',
      style,
      sx,
    },
    ref,
  ) => {
    return (
      <MuiIconButton
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
        classes={classes}
        className={className}
        color={color}
        data-testid={testid}
        disabled={disabled}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        edge={edge}
        id={id}
        onClick={onClick}
        ref={ref}
        size={size}
        style={style}
        sx={sx}
      >
        {children}
      </MuiIconButton>
    );
  },
);
export default IconButton;
