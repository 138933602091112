import React from 'react';
import ColorBox from './ColorBox';
import { RootBox, TitleTypography, GridBox } from './styles';
import { ColorBoxLabelProps } from './types';
import { INFORMATION_COLORS_GRID, BACKGROUND_COLORS } from './constants';

const InformationColorsComponent: React.FC<ColorBoxLabelProps> = () => (
  <RootBox>
    <TitleTypography variant="h3">Information Colors</TitleTypography>
    <GridBox>
      {INFORMATION_COLORS_GRID.map(({ color, label }) => (
        <ColorBox
          key={color}
          color={color}
          label={label}
          isSemantic={label.includes('Semantic')}
        />
      ))}
      {BACKGROUND_COLORS.map(({ color, label }) => (
        <ColorBox
          key={color}
          color={color}
          label={label}
          isSemantic={label.includes('Semantic')}
          bold
        />
      ))}
    </GridBox>
  </RootBox>
);

export default InformationColorsComponent;
