import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@octanner/prism-icons';

export const StyledChipButton = styled(ButtonBase)<{ closeColor: string }>(
  ({ closeColor }) => ({
    borderRadius: '16px',
    padding: '0',
    '&:focus': {
      outline: 'none',
    },
    height: '18px',
    width: '18px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',
    '&:hover': {
      transition: 'background-color 0.3s',
      backgroundColor: closeColor,
    },
  }),
);

export const StyledClose = styled(Close)({
  height: '10px',
  width: '10px',
});
