import Drawer from '../Drawer';
import Divider from '../Divider';
import List from '../List';
import ListItem from '../ListItem';
import ListItemText from '../ListItemText';
import React, { FC, useContext, useState } from 'react';
import IconButton from '../IconButton';
import { Close as CloseIcon } from '@octanner/prism-icons';
import IdentityInfo from '../IdentityInfo';
import {
  FullWidthSingleSearch,
  ListItemStyle,
  Logo,
  LogoImage,
} from './styles';
import CultureCloudGradientLogo from './CultureCloudGradientLogo';
import { MobileDrawerProps } from './types';
import useLayoutLinks, { MenuLinks } from './hooks/useLayoutLinks';
import { useTranslation } from 'react-i18next';
import LayoutContext from '../context/Layout';
import { getFullName } from '@octanner/prism-utilities';
import { useEnabledPreferredPronouns } from './hooks/useEnabledPreferredPronouns';
import { useIdentitySearch } from './identitySearch';
import SingleSearch from '../SingleSearch';
import MobileListItems from './MobileListItems';

const MobileDrawer: FC<MobileDrawerProps> = ({
  navObj: { navOpen, setNavOpen },
  handleNavigation,
  multipleBanksObj,
  logoutUrl,
  handleonChange,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const identitySearchQuery = useIdentitySearch(searchText);
  const searchOptions = identitySearchQuery?.data || [];
  const [currentIdentityQuery, layoutQuery] = useContext(LayoutContext);
  const layout = layoutQuery?.data;
  const links = useLayoutLinks(layoutQuery?.data);
  const mobileLinks = links.menuLinks.filter(
    (link) => link.id !== MenuLinks.Logout,
  );
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const customerUuid = currentIdentity?.customer?.customerUuid;
  const { data: enablePronounData } = useEnabledPreferredPronouns(customerUuid);
  const enablePronouns = enablePronounData?.ccWebPreferredPronouns?.enabled;
  const fullName = getFullName(currentIdentity, enablePronouns);
  const avatarSrc = currentIdentity?.profile?.avatar;

  return (
    <Drawer
      open={navOpen}
      onClose={() => setNavOpen(false)}
      data-testid="mobile-drawer"
    >
      <List>
        <ListItem style={{ justifyContent: 'space-between' }}>
          <CultureCloudGradientLogo
            data-testid="culturecloud-logo"
            aria-label="Culture Cloud Logo"
          />
          <IconButton onClick={() => setNavOpen(false)} aria-label="close menu">
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem>
          <SingleSearch
            options={searchOptions}
            filterOptions={(option) => option}
            onChange={handleonChange}
            data-testid="search-cc-header"
            onInputChange={(e, _, reason) => {
              if (reason === 'input') {
                setSearchText((e.target as HTMLInputElement).value);
              } else if (reason == 'clear') {
                setSearchText('');
              }
            }}
            onBlur={() => {
              setNavOpen(false);
              setSearchText('');
            }}
            sx={FullWidthSingleSearch}
            inputValue={searchText}
            renderOption={(props, option) => (
              <li {...props}>
                <IdentityInfo
                  identity={option}
                  color="primary"
                  isMobile={true}
                />
              </li>
            )}
          />
        </ListItem>
        <MobileListItems
          links={mobileLinks}
          handleNavigation={handleNavigation}
          avatarSrc={avatarSrc}
          fullName={fullName}
          multipleBanksObj={multipleBanksObj}
          identifier="mobile-list-items"
        />
        <MobileListItems
          links={links.secondaryLinks}
          handleNavigation={handleNavigation}
          identifier="secondary-links"
        />
      </List>
      <Divider />
      <List>
        <MobileListItems
          links={links.primaryLinks}
          handleNavigation={handleNavigation}
          identifier="primary-links"
        />
      </List>
      <Divider />
      <List>
        <ListItem
          sx={ListItemStyle}
          className="fs-unmask"
          component="a"
          href={logoutUrl ?? '/logout'}
          data-testid="logout-link"
          onClick={handleNavigation(logoutUrl ?? '/logout')}
        >
          <ListItemText>{t('prism-header:logout')}</ListItemText>
        </ListItem>
      </List>
      {layout?.theme?.logo?.value && (
        <Logo>
          <LogoImage src={layout?.theme?.logo?.value} />
        </Logo>
      )}
    </Drawer>
  );
};

export default MobileDrawer;
