import { styled } from '@mui/material/styles';
import { Button, Grid } from '..';
import Typography from '@mui/material/Typography';
import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';
import { StyledTypographyProps } from './types';
import {
  Check as PassedIcon,
  Close as FailedIcon,
} from '@octanner/prism-icons';
import React from 'react';

const PREFIX = 'PasswordForm';

export const classes = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  colorSecondary: `${PREFIX}-colorSecondary`,
  colorWarning: `${PREFIX}-colorWarning`,
  bar: `${PREFIX}-bar`,
  barColorPrimary: `${PREFIX}-barColorPrimary`,
  barColorWarning: `${PREFIX}-barColorWarning`,
  barColorSecondary: `${PREFIX}-barColorSecondary`,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 10,
    borderRadius: 10,
  },

  [`& .${classes.colorPrimary}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.colorSecondary}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.colorWarning}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.bar}`]: {
    borderRadius: 0,
  },

  [`& .${classes.barColorPrimary}`]: {
    backgroundColor: tannerSemantic['success'].color,
  },

  [`& .${classes.barColorWarning}`]: {
    backgroundColor: '#E5B818',
  },

  [`& .${classes.barColorSecondary}`]: {
    backgroundColor: tannerSemantic['error'].color,
  },
}));

export const StyledUL = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const CancelButton = styled(Button)`
  margin-left: 16px;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  width: 100%;
`;

export const Icon = styled(
  ({
    isValidated,
    isPassed,
    ...props
  }: {
    isValidated?: boolean;
    isPassed?: boolean;
  }) => {
    if (isValidated) {
      return isPassed ? <PassedIcon {...props} /> : <FailedIcon {...props} />;
    } else {
      return (
        <Typography component="span" {...props}>
          {' '}
          &#8226;{' '}
        </Typography>
      );
    }
  },
)((props: { isPassed?: boolean; isValidated?: boolean }) => {
  let color = 'inherit';
  if (props.isValidated) {
    if (props.isPassed) {
      color = tannerSemantic['success']?.color;
    } else {
      color = tannerSemantic['error']?.color;
    }
  }
  return {
    width: 16,
    marginRight: props.isValidated ? 20 : 15,
    marginLeft: props.isValidated ? 5 : 10,
    color,
  };
});

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    !/isHeader|isPassed|isValidated/.test(String(prop)),
})<StyledTypographyProps>((props) => {
  let color = 'inherit';
  if (props.isValidated) {
    color = props.isPassed
      ? tannerSemantic['success'].color
      : tannerSemantic['error'].color;
  }
  return {
    display: 'flex',
    alignItems: 'center',
    marginBottom: props.isHeader ? 10 : 5,
    color,
  };
});
