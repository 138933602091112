import IconButton from '../IconButton';
import Typography from '../Typography';
import { tannerGray } from '../ThemeProvider/colors';
import { DialogActions, DialogContent, styled } from '@mui/material';
import Button from '../Button';
import { ArrowLeft } from '@octanner/prism-icons';

export const StyledButton = styled(Button)({
  color: tannerGray[700],
  '&:hover': {
    backgroundColor: tannerGray[200],
  },
  '&:focus': {
    backgroundColor: tannerGray[200],
  },
  '&.Mui-disabled': {
    color: tannerGray[700],
  },
});

export const StyledBackButton = styled(StyledButton)({
  paddingLeft: 9,
  '&:focus': {
    textDecoration: 'underline',
  },
  '&:active': {
    textDecoration: 'none',
  },
});

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'upperShadow' && prop !== 'isMobile',
})<
  React.HTMLAttributes<HTMLDivElement> & {
    upperShadow: boolean;
    isMobile: boolean;
  }
>(({ upperShadow = false, isMobile = false }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: isMobile ? '8px' : '16px 24px',
  boxShadow: upperShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
}));

export const StyledNoBackTextDiv = styled('div')({
  height: 40,
  width: 96,
});

export const StyledNoBackTextDivMobile = styled('div')({
  height: 40,
  width: 40,
});

export const StyledDefaultBackText = styled('div')({
  paddingTop: 1,
});

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'hideTitle',
})<{ hideTitle?: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 0;
  ${({ hideTitle }) =>
    hideTitle &&
    `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  `}
`;

export const StyledIconButton = styled(IconButton)({
  color: tannerGray['700'],
  '&:focus': {
    ':focus-visible': {
      backgroundColor: tannerGray['200'],
    },
  },
  '&:hover': {
    color: tannerGray['700'],
    backgroundColor: tannerGray['200'],
  },
});

export const StyledArrowLeft = styled(ArrowLeft)({
  marginBottom: 1,
  marginRight: -4,
});

export const CustomDialogActions = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>(({ isMobile }) => ({
  ...(isMobile && {
    padding: '8px',
  }),
}));

export const FullScreenContainer = styled('div')({
  maxWidth: '768px',
  margin: '10px auto',
  width: '100%',
});

export const StyledDialogContent = styled(DialogContent, {
  shouldForwardProp: (prop) => prop !== 'hasNoActionsAndNav',
})<{ hasNoActionsAndNav: boolean }>(({ hasNoActionsAndNav }) => ({
  ...(hasNoActionsAndNav && {
    padding: '0',
  }),
}));
