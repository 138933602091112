import { ReactSdkContext } from 'launchdarkly-react-client-sdk';
import React from 'react';
import LaunchDarklyContext from './LaunchDarklyContext';

export type LaunchDarklyProviderProps = {
  reactSDKContext: ReactSdkContext;
  children: React.ReactNode;
};

export default function LaunchDarklyProvider({
  reactSDKContext,
  children,
}: LaunchDarklyProviderProps) {
  return (
    <LaunchDarklyContext.Provider value={reactSDKContext}>
      {children}
    </LaunchDarklyContext.Provider>
  );
}
