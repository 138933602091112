import React, { FC, useContext } from 'react';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import LayoutContext from '../context/Layout';
import useLayoutLinks from './hooks/useLayoutLinks';
import useTranslations from './hooks/useTranslations';
import useHeaderTheme from './hooks/useHeaderTheme';
import ImpersonationBanner from '../ImpersonationBanner';
import useCatalogLink from '../hooks/useCatalogLink';
import useAnalytics from './hooks/useAnalytics';
import { HeaderContainer } from './styles';
import { HeaderProps } from './types';
import VariableHeader from './VariableHeader';
import MobileContainer from './MobileContainer';
import { getMultipleBanks, sortAccess } from './multipleBanksFunctions';
import { useFlags } from '@octanner/prism-utilities';

const Header: FC<HeaderProps> = ({
  fullStoryOrg,
  isImpersonating,
  specialPointName,
  onStopImpersonation,
  routerPath,
  routerPush,
  routerRoutes = [],
  referrerUrl,
  variant = 'normal',
  logoutUrl,
  ...props
}) => {
  const headerTheme = useHeaderTheme();
  const isMobile = useMediaQuery('@media (max-width: 768px)');
  const [currentIdentityQuery, layoutQuery, localeQuery] =
    useContext(LayoutContext);
  const { lang } = useTranslations(
    currentIdentityQuery?.data?.currentIdentity,
    localeQuery?.data?.customerCoreLocaleMapsByCustomer?.nodes,
  );
  const currentIdentity = currentIdentityQuery?.data?.currentIdentity;
  const links = useLayoutLinks(layoutQuery?.data);
  const bankUrl = useCatalogLink({ referrerUrl, lang });
  const { ccuiMultipleBanksP240315 } = useFlags();

  const testRoute = (url: string) => (route: string) =>
    route.length > 1 ? url.startsWith(route) : url === route;

  const layout = layoutQuery?.data;
  const headerView = variant === 'normal' ? true : false;

  const bank =
    layout?.bankBalance?.find((bank) => bank.defaultBank) ||
    layout?.bankBalance?.[0];

  const hasMultipleBank =
    layout?.bankBalance &&
    layout.bankBalance.length > 1 &&
    ccuiMultipleBanksP240315;

  const { vettedMultipleBanks, accessCodes } = getMultipleBanks(
    layout,
    currentIdentity,
    hasMultipleBank!,
  );

  useAnalytics({
    currentIdentity,
    fullStoryOrg,
    links,
    bank,
  });

  return (
    <ThemeProvider theme={headerTheme}>
      {currentIdentity && isImpersonating && (
        <ImpersonationBanner
          identity={currentIdentity}
          onStopImpersonation={onStopImpersonation}
        />
      )}
      <HeaderContainer {...props}>
        {isMobile ? (
          <MobileContainer
            multipleBanksObj={{
              hasMultipleBank: hasMultipleBank,
              multipleBanks: vettedMultipleBanks,
              accessCodes,
              bankUrl,
            }}
            routerObj={{ routerPush, routerRoutes, testRoute }}
            logoutUrl={logoutUrl}
          />
        ) : (
          <VariableHeader
            headerView={headerView}
            logoutUrl={logoutUrl}
            multipleBanksObj={{
              hasMultipleBank: hasMultipleBank!,
              multipleBanks: vettedMultipleBanks?.concat(
                sortAccess(accessCodes),
              ),
              suppliedSpecialPointName: specialPointName,
              bank: bank,
              bankUrl,
            }}
            routerObj={{ routerPush, routerRoutes, routerPath, testRoute }}
          />
        )}
      </HeaderContainer>
    </ThemeProvider>
  );
};

export default Header;
