const tannerGray = {
  color: '#70707A',
  background: '#F8F8FA',
  25: '#FBFBFA',
  50: '#F8F8FA',
  100: '#F1F1F6',
  200: '#E1E1EB',
  300: '#C4C4CE',
  400: '#ABABB8',
  500: '#8E8E98',
  600: '#70707A',
  700: '#52525C',
  800: '#363640',
  900: '#21212B',
  1000: '#10101A',
};

const tannerPurple = {
  50: '#EBE3FA',
  100: '#D7C6F5',
  200: '#C3AAF0',
  300: '#AF8EEB',
  400: '#8D5EE2',
  500: '#7942DD',
  600: '#6738BC',
  700: '#552E9B',
  800: '#43247A',
  900: '#301A58',
};

const tannerBlue = {
  50: '#ECF4FB',
  100: '#A2CDF2',
  200: '#1194F6',
  300: '#0F84DC',
  500: '#0060E0',
};

const tannerSemantic = {
  success: {
    color: '#07702A',
    background: '#D9F2E9',
    25: '#F0FAF7',
    50: '#D9F2E9',
    100: '#AED8C4',
    200: '#84BE9D',
    300: '#5AA377',
    400: '#2C854A',
    500: '#07702A',
    600: '#065F24',
    700: '#054E17',
    800: '#043E17',
    900: '#032D11',
    1000: '#010E05',
  },
  info: {
    color: '#0060E0',
    background: '#DEEBFC',
    25: '#F2F7FD',
    50: '#DEEBFC',
    100: '#B4D1F7',
    200: '#85B4F1',
    300: '#4D90E5',
    400: '#2A7BE6',
    500: '#0060E0',
    600: '#0052BF',
    700: '#00449D',
    800: '#00357B',
    900: '#00275A',
    1000: '#001D43',
  },
  warning: {
    color: '#B1480B',
    background: '#FFF4D9',
    25: '#FFFBF2',
    50: '#FFF4D9',
    100: '#FFDAAE',
    200: '#F6B27A',
    300: '#E18B4E',
    400: '#CB6327',
    500: '#B1480B',
    600: '#963D09',
    700: '#7C3208',
    800: '#612806',
    900: '#471D04',
    1000: '#351603',
  },
  error: {
    color: '#D10A12',
    background: '#FDE5E5',
    25: '#FEF6F6',
    50: '#FDE5E5',
    100: '#F9BABC',
    200: '#F6858A',
    300: '#E95F63',
    400: '#D93138',
    500: '#D10A12',
    600: '#B2080F',
    700: '#93070C',
    800: '#73050A',
    900: '#540407',
    1000: '#3F0305',
  },
  archived: { color: tannerGray['800'], background: tannerGray['100'] },
};

const tannerDanger = {
  main: '#FDE5E5',
  light: '#D10A12',
};

const tannerWhite = '#FFFFFF';
const tannerBlack = '#000000';

export {
  tannerGray,
  tannerPurple,
  tannerBlue,
  tannerSemantic,
  tannerDanger,
  tannerWhite,
  tannerBlack,
};
