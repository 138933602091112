import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import React from 'react';
import {
  tannerSemantic,
  tannerWhite,
  tannerGray,
} from '../ThemeProvider/colors';
import Typography, { TypographyProps } from '../Typography';

export type BadgeSecondaryProps = {
  children: string | number;
  variant?: 'success' | 'info' | 'warning' | 'error' | 'archived';
  typographyProps?: TypographyProps;
  sx?: SxProps;
};

const BadgeSecondary = React.forwardRef<HTMLSpanElement, BadgeSecondaryProps>(
  ({ children, variant = 'info', typographyProps, sx }, ref) => (
    <Box
      component="div"
      ref={ref}
      sx={{
        display: 'inline-block',
        alignItems: 'center',
        backgroundColor:
          variant === 'archived'
            ? tannerGray['600']
            : tannerSemantic[variant].color,
        border: 'none',
        borderRadius: 1,
        color: tannerWhite,
        px: '3px',
        ...sx,
      }}
    >
      <Typography
        {...typographyProps}
        sx={{
          lineHeight: '16px',
          color: 'inherit',
          fontWeight: 800,
          fontSize: 12,
          ...typographyProps?.sx,
        }}
      >
        {children}
      </Typography>
    </Box>
  ),
);

export default BadgeSecondary;
