import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import DialogBase from '../DialogBase';
import { mobileMediaQuery } from '.';

const ModalBase: typeof DialogBase = ({
  children,
  id,
  fullScreen,
  ...props
}) => {
  const isMobile = useMediaQuery(mobileMediaQuery);

  return (
    <DialogBase
      fullWidth
      fullScreen={fullScreen !== undefined ? fullScreen : isMobile}
      aria-labelledby={`${id}-title`}
      id={id}
      {...props}
    >
      {children}
    </DialogBase>
  );
};

export default ModalBase;
